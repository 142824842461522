import React, {Component} from "react";
import {round} from "../../../libs/format";
import ContentEditable from 'react-contenteditable';
import {findDOMNode} from "react-dom";

import "../../Ads.css";

const MIN_INCREMENT = 0.001;  // $0.001

export class EditableAd extends Component {
  constructor(props) {
    super(props);

    this.contentEditable = null;

    this.state = {
      html: props.text
    };
  }
  componentDidMount() {
    if(this.contentEditable) {
      findDOMNode(this.contentEditable).focus();

      //move caret to end of ad
      const html = this.state.html;
      this.setState({html: ''},() => this.setState({html}) );
    }
  }

  onChange(html) {
    let sanitizedHtml = html;
    if(sanitizedHtml.split('</div>').length > 3){
      // limit to 3 lines
      sanitizedHtml = sanitizedHtml.replace('<div><br></div>', '');
    }
    this.setState({html:sanitizedHtml});

    const text = sanitizedHtml.replace(/<div>/gi,'\n').replace(/<\/div>/gi,'').replace('<br>', '');
    const sanititzedText = text.replace(/</g, '&lt;').replace(/>/g, '&gt;')

    this.props.onChange(sanititzedText);
  }

  render() {
    return (
      <div className="Ad">
        <div id="next">
          <a onClick={() => this.props.next()}>
            Next &rang;
          </a>
        </div>
        <div>
          <div id="advert">
            <ContentEditable
              html={this.state.html}
              onChange={e => this.onChange(e.target.value)}
              ref={(c) => this.contentEditable = c}
            />
          </div>
        </div>
        <div id="advertise">
          <a href="#">
            &#9998; ${round(parseFloat(this.props.amount), 3)}
          </a>
        </div>
      </div>)
  }
};
