import React, {Component} from "react";
import {round} from "../../../libs/format";
import ContentEditable from 'react-contenteditable';

import "../../Ads.css";
import {findDOMNode} from "react-dom";

const MIN_INCREMENT = 0.001;  // $0.001

export class EditableBid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      html: 'Your Bid: $' + props.amount
    };
  }
  componentDidMount() {
    if(this.contentEditable) {
      findDOMNode(this.contentEditable).focus();

      //move caret to end of ad
      const html = this.state.html;
      this.setState({html: ''},() => this.setState({html}) );
    }
  }
  onChange(html) {
    let sanitizedHtml = html;
    let amount = this.props.amount;
    if(sanitizedHtml.split('</div>').length > 1){
      // limit to 1 lines
      sanitizedHtml = sanitizedHtml.replace('<div><br></div>', '');
    }
    if(!sanitizedHtml.startsWith('Your Bid: $') ) {
      sanitizedHtml = 'Your Bid: $' + this.props.amount;
    }
    amount = parseFloat(sanitizedHtml.replace('Your Bid: $',''));
    if(typeof amount !== 'number') {
      sanitizedHtml = 'Your Bid: $' + this.props.amount;
      amount = this.props.amount;
    }

    this.setState({html:sanitizedHtml});
    this.props.onChange(amount);
  }

  render() {
    return (
      <div className="Ad">
        <div id="prev">
          <a href="#" onClick={() => this.props.prev()}> &lang; Prev </a>
        </div>
        <div id="next">
          <a onClick={() => this.props.next()}>
            Review &rang;
          </a>
        </div>
        <div>
          <div id="advert">
            Min Bid: ${round(parseFloat(this.props.minBid), 3)}

            <ContentEditable
              html={this.state.html}
              onChange={e => this.onChange(e.target.value)}
              ref={(c) => this.contentEditable = c}
            />
          </div>
        </div>
        <div id="advertise">
          <a href="#">
            &#9998; ${round(parseFloat(this.props.amount), 3)}
          </a>
        </div>
      </div>)
  }
};
