import React, {Component} from "react";

import "./Settings.css";
import {API} from "aws-amplify";
import {Panel} from "react-bootstrap";
import PanelHeading from "react-bootstrap/es/PanelHeading";
import PanelTitle from "react-bootstrap/es/PanelTitle";
import PanelBody from "react-bootstrap/es/PanelBody";
import {round} from "../libs/format";

export default class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      earnings: {}
    };
  }

  async componentDidMount() {
    try {
      const earnings = await this.earnings();

      this.setState({
        earnings,
      });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  earnings() {
    return API.get("invad", "/earnings");
  }

  render() {
    return (
      <div>
        {!this.state.isLoading &&
          <div className="list">
            <h1>Account</h1>

            <Panel>
              <PanelBody>
                <h3>Total earnings this period: <strong>USD ${round(this.state.earnings.total, 3)}</strong></h3>
                { this.state.earnings.total > 0 &&
                <a href={`mailto:raz@invad.com?subject=Withdrawing%20$${round(this.state.earnings.total, 3)}`}>Withdraw Earnings</a>
                }
                <br />

                <h3>Earnings by ad space</h3>
                <br />

                {this.state.earnings.spaces.map(space =>
                  <Panel>
                    <PanelHeading>
                      <PanelTitle>
                        {space.name}
                      </PanelTitle>
                    </PanelHeading>
                    <PanelBody>
                      {space.bids
                        .sort((bidA, bidB) => bidA.updatedAt > bidB.updatedAt ? -1 : 1)
                        .map(bid =>
                          <div>
                            USD ${bid.amount} - { new Date(bid.updatedAt).toLocaleString()}
                          </div>)}
                      {space.bids.length === 0 &&
                      <div>
                        - No bids yet -
                      </div>}
                    </PanelBody>
                  </Panel>
                )}
                {this.state.earnings.spaces.length === 0 &&
                <div>
                  - No ad spaces yet -
                </div>
                }
              </PanelBody>
            </Panel>
          </div>
        }
      </div>
    );
  }
}
