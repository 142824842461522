import React, {Component} from "react";
import { API, Storage } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { s3Upload } from "../../libs/awsLib";
import config from "../../config";
import "../Ads.css";

export default class Ads extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      isDeleting: null,
      ad: null,
      text: "",
      url: "",
      attachmentURL: null
    };
  }

  async componentDidMount() {
    try {
      let attachmentURL;
      const ad = await this.getAd();
      const { text, url, attachment } = ad;

      if (attachment) {
        attachmentURL = await Storage.vault.get(attachment);
      }

      this.setState({
        ad,
        text,
        url,
        attachmentURL
      });
    } catch (e) {
      alert(e);
    }
  }

  getAd() {
    return API.get("invad", `/ads/${this.props.match.params.id}`);
  }

  saveAd(ad) {
    return API.put("invad", `/ads/${this.props.match.params.id}`, {
      body: ad
    });
  }

  deleteAd() {
    return API.del("invad", `/ads/${this.props.match.params.id}`);
  }

  validateForm() {
    return this.state.text.length > 0;
  }

  formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  handleSubmit = async event => {
    let attachment;

    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    this.setState({ isLoading: true });

    try {
      if (this.file) {
        attachment = await s3Upload(this.file);
      }

      await this.saveAd({
        text: this.state.text,
        url: this.state.url,
        attachment: attachment || this.state.ad.attachment
      });
      this.props.history.push("/ads");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this ad?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteAd();
      this.props.history.push("/ads");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  render() {
    return (
      <div className="Ads">
        {this.state.ad &&
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="text">
              <ControlLabel>Ad Text</ControlLabel>
              <FormControl
                onChange={this.handleChange}
                value={this.state.text}
                componentClass="input"
              />
            </FormGroup>
            <FormGroup controlId="text">
              <ControlLabel>Url</ControlLabel>

              <FormControl
                onChange={this.handleChange}
                value={this.state.url}
                componentClass="input"
              />
            </FormGroup>


            {/*<Fragment>*/}
              {/*{this.state.ad.attachment &&*/}
                {/*<FormGroup>*/}
                  {/*<ControlLabel>Attachment</ControlLabel>*/}
                  {/*<FormControl.Static>*/}
                    {/*<a*/}
                      {/*target="_blank"*/}
                      {/*rel="noopener noreferrer"*/}
                      {/*href={this.state.attachmentURL}*/}
                    {/*>*/}
                      {/*{this.formatFilename(this.state.ad.attachment)}*/}
                    {/*</a>*/}
                  {/*</FormControl.Static>*/}
                {/*</FormGroup>}*/}
              {/*<FormGroup controlId="file">*/}
                {/*{!this.state.ad.attachment &&*/}
                  {/*<ControlLabel>Attachment</ControlLabel>}*/}
                {/*<FormControl onChange={this.handleFileChange} type="file" />*/}
              {/*</FormGroup>*/}
            {/*</Fragment>*/}


            <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Save"
              loadingText="Saving…"
            />
            <LoaderButton
              block
              bsStyle="danger"
              bsSize="large"
              isLoading={this.state.isDeleting}
              onClick={this.handleDelete}
              text="Delete"
              loadingText="Deleting…"
            />
          </form>}
      </div>
    );
  }
}
