
export function getSpaceCode(spaceId) {
  return(
`<iframe src="https://ad.invad.com/s/${spaceId}"
  width="358" scrolling="no" frameBorder="0" seamless="" height="100"
  style="max-width: 358px; min-width: 225px; box-sizing: border-box !important;  background-color: #000">
</iframe>`);
}

export const getSampleAd = (spaceId) => (
{
  __html: `<iframe src="https://ad.invad.com/s/${spaceId}"\n` +
  '   width="358" scrolling="no" frameBorder="0" seamless="" height="100"\n' +
  '   style="max-width: 358px; min-width: 225px; box-sizing: border-box !important; background-color: #000">\n' +
  '</iframe>'
});
