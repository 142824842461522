import React, {Component} from "react";
import isURL from 'validator/lib/isURL';
import isLength from 'validator/lib/isLength';

import { API } from "aws-amplify/lib/index";
import {FormGroup, FormControl, ControlLabel, Panel, PageHeader} from "react-bootstrap";
import PanelBody from "react-bootstrap/es/PanelBody";

import "../NewAd.css";
import {Ad} from "../Ads/Ad";
import PanelTitle from "react-bootstrap/es/PanelTitle";
import PanelHeading from "react-bootstrap/es/PanelHeading";
import {getSampleAd} from "../../libs/iframeLib";

const MIN_BID = 0.00001;

export default class NewAdBid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      space: null,
      url: '',
      text: '',
      amount: MIN_BID,
      minBid: {amount: 0.00001},
      reviewingAd: false,
    };
  }

  async componentDidMount() {
    try {
      const space = await this.getSpace();
      const minBid = await this.minBid();

      this.setState({
        space,
        minBid,
        amount: minBid.amount,
      });
    } catch (e) {
      alert(e);
    }
  }


  getSpace() {
    return API.get("invad", `/spaces/${this.props.match.params.spaceId}`);
  }

  minBid() {
    return API.get("invad", `/minBid/${this.props.match.params.spaceId}`);
  }

  createAd(ad) {
    return API.post("invad", "/ads", {
      body: ad
    });
  }

  createInvoice(invoice) {
    return API.post("invad", "/invoices", {
      body: invoice
    });
  }


  createBid(bid) {
    return API.post("invad", "/bids", {
      body: bid
    });
  }

  async updateTopBid() {
    const minBid = await this.minBid();

    this.setState({
      minBid,
    });
  }

  async validateForm() {
    if(!isURL(this.state.url)){
      alert("Invalid ad url.  Please double check.");
      return false;
    }
    if(!isLength(this.state.text, { min:3 })) {
      alert("Ad text must be at least 3");
      return false;
    }

    await this.updateTopBid();

    if( this.state.amount < this.state.minBid.amount )
    {
      alert(`Bid is below top bid of ${this.state.minBid.amount}. Only ad with top bid will display.`);
      return false;
    }
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    if(!await this.validateForm()){
      return;
    }

    this.setState({ isLoading: true });

    try {
      const ad = await this.createAd({
        attachment: null,
        text: this.state.text,
        url: this.state.url,
      });

      const bid = await this.createBid({
        spaceId: this.state.space.spaceId,
        adId: ad.adId,
        amount: this.state.amount
      });

      const invoice = await this.createInvoice({
        bidId: bid.bidId,
        amount: this.state.amount,
      });

      window.btcpay.showInvoice(invoice.invoiceId);

      // window.btcpay.onModalWillEnter(yourCallbackFunction);
      window.btcpay.onModalWillLeave(() => {
        this.setState({isLoading: false});
        this.props.history.push(`/adbids/${bid.bidId}`);
      });

    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <div className="NewAd">
        {!this.state.isLoading && this.state.space &&
        <form onSubmit={this.handleSubmit}>
          <PageHeader>Create a new ad bid</PageHeader>
          <br />
          <ControlLabel>Ad will show exclusively in the target ad space unless it is outbid or the end of the month is reached, April 30, 2019.</ControlLabel>
          <br />
          <br />
          <br />
          <Panel>
            <PanelHeading>
              <PanelTitle>
                Target ad space
              </PanelTitle>
            </PanelHeading>
            <PanelBody>
              <ControlLabel>Name</ControlLabel>
              <pre>{this.state.space.name}</pre>
              <ControlLabel>Description</ControlLabel>
              <pre>{this.state.space.description}</pre>
              <ControlLabel>Space Url</ControlLabel>
              <pre>{this.state.space.liveUrl}</pre>
              <ControlLabel>Minimum Bid</ControlLabel>
              <pre>${this.state.minBid.amount}</pre>
              <ControlLabel>Current Ad</ControlLabel>
              <div>
                {!this.state.reviewingAd ?
                  <a href="#" onClick={() => this.setState({reviewingAd: true})}>Review</a>
                  :
                  <div>
                    <div className="sampleAd" dangerouslySetInnerHTML={getSampleAd(this.state.space.spaceId)}/>
                    <a href="#" onClick={() => alert("Thank you.  The ad will be reviewed by the ad space owner.")}>Report </a>
                  </div>
                }
              </div>

            </PanelBody>
          </Panel>
          <Panel>
            <PanelHeading>
              <PanelTitle>
                Your Ad
              </PanelTitle>
            </PanelHeading>
            <PanelBody>
              <ControlLabel>Preview</ControlLabel>
              <Ad url={this.state.url} text={this.state.text} amount={this.state.amount} />
              <br />

              <FormGroup controlId="text">
                <ControlLabel>Text</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.text}
                  bsSize="small"
                  type="text"
                  componentClass="textarea"
                  style={{ height: 120 }}
                />
              </FormGroup>
              <FormGroup controlId="url">
                <ControlLabel>Url</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.url}
                  componentClass="input"
                />
              </FormGroup>

              <FormGroup controlId="amount">
                <ControlLabel>Bid (USD)</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.amount}
                  componentClass="input"
                />
              </FormGroup>

              <input
                type="image"
                onClick={this.handleSubmit}
                src="https://pay.invad.com/img/paybutton/pay.png"
                name="submit"
                style={{width:'200px'}}
                alt="Pay with BtcPay, Self-Hosted Bitcoin Payment Processor"
              />

            </PanelBody>
          </Panel>

        </form>
        }
      </div>
    );
  }
}
