import React, { Component } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import "../NewAd.css";

export default class NewSpace extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      name: "",
      description: "",
      liveUrl: ""
    };
  }

  createSpace(space) {
    return API.post("invad", "/spaces", {
      body: space
    });
  }

  validateForm() {
    //TODO: Validation
    if( !this.state.name.length > 3 )
    {
      return false;
    }
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const newSpace = await this.createSpace({
        name: this.state.name,
        description: this.state.description,
        liveUrl: this.state.liveUrl,
      });
      this.props.history.push(`/spaces/${newSpace.spaceId}`);
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }


  render() {
    return (
      <div className="NewAd">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="name">
            <ControlLabel>Ad Space Name</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.name}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup controlId="description">
            <ControlLabel>Description</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.description}
              componentClass="textarea"
            />
          </FormGroup>
          <FormGroup controlId="liveUrl">
            <ControlLabel>Sample Url</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.liveUrl}
              componentClass="input"
            />
          </FormGroup>

          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}
