import React, {Component} from "react";
import "./Home.css";
import {getSampleAd} from "../libs/iframeLib";

const invadSpaces = [
  {
    "spaceId": "1db7e130-4aca-11e9-a5d4-99a88ff25024",
    "userId": "us-west-2:323d52d9-a196-4925-803f-48d17b230595",
    "updatedAt": 1553056755395,
    "createdAt": 1553056755395,
    "description": "Invad Ad Space 1 description",
    "name": "Invad Ad Space 1",
    "liveUrl": "https://invad.com/"
  },
  {
    "spaceId": "7b7665b0-5b57-11e9-bfb4-350ce710457e",
    "userId": "us-west-2:323d52d9-a196-4925-803f-48d17b230595",
    "updatedAt": 1554876690315,
    "createdAt": 1554876690315,
    "description": "Invad Ad space 2 description",
    "name": "Invad Ad Space 2",
    "liveUrl": "https://invad.com/"
  },
  {
    "spaceId": "375b5ef0-5b5a-11e9-bfb4-350ce710457e",
    "userId": "us-west-2:323d52d9-a196-4925-803f-48d17b230595",
    "updatedAt": 1554877864543,
    "createdAt": 1554877864543,
    "description": "Invad Ad Space 3 description",
    "name": "Invad Ad Space 3",
    "liveUrl": "https://invad.com/"
  },
  {
    "spaceId": "a3397d80-5b61-11e9-952a-e9f48f14079e",
    "userId": "us-west-2:323d52d9-a196-4925-803f-48d17b230595",
    "updatedAt": 1554881051993,
    "createdAt": 1554881051992,
    "description": "Invad Ad Space 4 description",
    "name": "Invad Ad Space 4",
    "liveUrl": "https://invad.com/"
  },
  {
    "spaceId": "d88f3510-5b61-11e9-952a-e9f48f14079e",
    "userId": "us-west-2:323d52d9-a196-4925-803f-48d17b230595",
    "updatedAt": 1554881141473,
    "createdAt": 1554881141473,
    "description": "Invad Ad Space 5 description",
    "name": "Invad Ad Space 5",
    "liveUrl": "https://invad.com/"
  },
  {
    "spaceId": "e13987b0-5b61-11e9-952a-e9f48f14079e",
    "userId": "us-west-2:323d52d9-a196-4925-803f-48d17b230595",
    "updatedAt": 1554881156011,
    "createdAt": 1554881156011,
    "description": "Invad Ad Space 6 description",
    "name": "Invad Ad Space 6",
    "liveUrl": "https://invad.com/"
  },
  {
    "spaceId": "1f29da70-5b62-11e9-952a-e9f48f14079e",
    "userId": "us-west-2:323d52d9-a196-4925-803f-48d17b230595",
    "updatedAt": 1554881259927,
    "createdAt": 1554881259927,
    "description": "Invad Ad Space 7 description",
    "name": "Invad Ad Space 7",
    "liveUrl": "https://invad.com/"
  },
  {
    "spaceId": "29e9edb0-5b62-11e9-952a-e9f48f14079e",
    "userId": "us-west-2:323d52d9-a196-4925-803f-48d17b230595",
    "updatedAt": 1554881277963,
    "createdAt": 1554881277963,
    "description": "Invad Ad Space 8 description",
    "name": "Invad Ad Space 8",
    "liveUrl": "https://invad.com/"
  },
  {
    "spaceId": "319a4ff0-5b62-11e9-952a-e9f48f14079e",
    "userId": "us-west-2:323d52d9-a196-4925-803f-48d17b230595",
    "updatedAt": 1554881290863,
    "createdAt": 1554881290863,
    "description": "Invad Ad Space 9 description",
    "name": "Invad Ad Space 9",
    "liveUrl": "https://invad.com/"
  }
];

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      spaces: [],
      ads: []
    };
  }

  async componentDidMount() {
    try {
      const spaces = invadSpaces;

      this.setState({
        spaces,
        ads: spaces
          // .sort((spaceA, spaceB) => minBids[spaceA.spaceId] > minBids[spaceB.spaceId] ?  -1 : 1)
          .map(space => getSampleAd(space.spaceId))
      });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }



  render() {
    return (
      <div className="Home">
        <br/>
        <h3 className="centered">Invad: Permission-Free Ads</h3>
        <h5 className="centered"><img className="bolt" src="/ln-bolt.png"/> Powered by the Lightning Network <img
          className="bolt" src="/ln-bolt.png"/></h5>
        <br/>
        <div className="list">
          <br />
          <strong>
          <p className="centered">Bid on any ad space below or host your own.</p>
          </strong>
          <br />
          <div className="sampleAds">
            { !this.state.isLoading &&
              this.state.ads.map((ad,index) => <div className="sampleAd" key={index} dangerouslySetInnerHTML={ad} />) }
          </div>
        </div>

        <br />
        { !this.state.isLoading &&
        <div className="centered">
          <a href="/spaces/new">Create ad space for your website</a>
          <br />
          <br />
          <br />
        </div>
        }
      </div>
    );
  }
}
