import React, {Component} from "react";
import {round} from "../../../libs/format";

import "../../Ads.css";
import {printText} from "../Ad";

const MIN_INCREMENT = 0.001;  // $0.001

export class EditablePay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      html: 'Your Bid: $' + props.amount
    };
  }
  onChange(html) {
    let sanitizedHtml = html;
    let amount = this.props.amount;
    if(sanitizedHtml.split('</div>').length > 1){
      // limit to 1 lines
      sanitizedHtml = sanitizedHtml.replace('<div><br></div>', '');
    }
    if(!sanitizedHtml.startsWith('Your Bid: $') ) {
      sanitizedHtml = 'Your Bid: $' + this.props.amount;
    }
    amount = parseFloat(sanitizedHtml.replace('Your Bid: $',''));
    if(typeof amount !== 'number') {
      sanitizedHtml = 'Your Bid: $' + this.props.amount;
      amount = this.props.amount;
    }

    this.setState({html:sanitizedHtml});
    this.props.onChange(amount);
  }

  render() {
    return (
      <div className="Ad">
        <div id="prev">
          <a href="#" onClick={() => this.props.prev()}> &lang; Prev </a>
        </div>
        {this.props.bidId && <div id="next">
          <a href={`https://invad.com/adbids/${this.props.bidId}`} target="_blank" rel="nofollow">
            Pay &rang;
          </a>
        </div>}
        <div>
          <div id="advert">
            <a href={this.props.url} target="_blank" rel="nofollow">{printText(this.props.text)}</a>
          </div>
        </div>
        <div id="advertise">
          <a href="#">
            &#9998; ${round(parseFloat(this.props.amount), 3)}
          </a>
        </div>
      </div>)
  }
};
