import React, {Fragment} from "react";
import {round} from "../../libs/format";

import "../Ads.css";

const MIN_INCREMENT = 0.001;  // $0.001


export const printText = text => {
  if(!text) {
    return <Fragment>
      {'Ad text'}
    </Fragment>
  }
  const lines = text.split('\n');
  return(
    <Fragment>
      {lines.map((line, index) =>
        <Fragment>
          {line}
          { index < line.length -1 && <br /> }
        </Fragment>)}
    </Fragment>);
};

export const Ad = (props) => (
  <div className="Ad">
    <div id="advert"><a href={props.url} target="_blank" rel="nofollow">{printText(props.text)}</a></div>
    <div id="advertise">
      <a href="https://invad.com/ads/new" target="_blank" rel="nofollow">
        &#9998; ${round(parseFloat(props.amount),3)}
      </a>
    </div>
  </div>
);
