import React, { Component } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import "../Ads.css";

export default class Ads extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      isDeleting: null,
      bid: null,
      adId: "",
      amount: "",
    };
  }

  async componentDidMount() {
    try {
      const bid = await this.getBid();
      const { spaceId, adId, amount } = bid;


      this.setState({
        bid,
        spaceId,
        adId,
        amount
      });
    } catch (e) {
      alert(e);
    }
  }

  getBid() {
    return API.get("invad", `/bids/${this.props.match.params.id}`);
  }

  saveBid(bid) {
    return API.put("invad", `/bids/${this.props.match.params.id}`, {
      body: bid
    });
  }

  deleteBid() {
    return API.del("invad", `/bids/${this.props.match.params.id}`);
  }

  validateForm() {
    return this.state.amount > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.saveBid({
        adId: this.state.adId,
        amount: this.state.amount,
      });
      this.props.history.push("/bids");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this ad bid?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteBid();
      this.props.history.push("/bids");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  render() {
    return (
      <div className="Ads">
        {this.state.bid &&
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="adId">
              <ControlLabel>Ad Id</ControlLabel>

              <FormControl
                onChange={this.handleChange}
                value={this.state.adId}
                componentClass="input"
              />
            </FormGroup>
            <FormGroup controlId="amount">
              <ControlLabel>Amount</ControlLabel>
              <FormControl
                onChange={this.handleChange}
                value={this.state.amount}
                componentClass="input"
              />
            </FormGroup>

            <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Save"
              loadingText="Saving…"
            />
            <LoaderButton
              block
              bsStyle="danger"
              bsSize="large"
              isLoading={this.state.isDeleting}
              onClick={this.handleDelete}
              text="Delete"
              loadingText="Deleting…"
            />
          </form>}
      </div>
    );
  }
}
