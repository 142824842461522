import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import "../Home.css";

export default class SpacesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      spaces: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const spaces = await this.spaces();
      this.setState({ spaces });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  spaces() {
    return API.get("invad", "/spaces");
  }

  renderSpacesList(spaces) {
    return [{}].concat(spaces).sort((a, b) => a.createdAt > b.createdAt ?  -1 : 1).map(
      (space, i) =>
        i !== 0
          ? <LinkContainer
              key={space.spaceId}
              to={`/spaces/${space.spaceId}`}
            >
              <ListGroupItem header={space.name.trim().split("\n")[0]}>
                {"Created: " + new Date(space.createdAt).toLocaleString()}
              </ListGroupItem>
            </LinkContainer>
          : <LinkContainer
              key="new"
              to="/spaces/new"
            >
              <ListGroupItem>
                <h4>
                  <b>{"\uFF0B"}</b> List your ad space
                </h4>
              </ListGroupItem>
            </LinkContainer>
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Invad</h1>
        <p>Simple, instant, permission-free ads</p>
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to="/signup" className="btn btn-success btn-lg">
            Sign Up
          </Link>
        </div>
      </div>
    );
  }

  renderAuthenticated() {
    return (
      <div className="list">
        <PageHeader>My Ad Spaces</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderSpacesList(this.state.spaces)}
        </ListGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderAuthenticated() : this.renderLander()}
      </div>
    );
  }
}
