import React, { Component } from "react";
import { API } from "aws-amplify";
import {FormGroup, ControlLabel, Panel, PageHeader} from "react-bootstrap";
import "../Ads.css";
import {formatStatus} from "../../libs/format";
import PanelHeading from "react-bootstrap/es/PanelHeading";
import PanelTitle from "react-bootstrap/es/PanelTitle";
import PanelBody from "react-bootstrap/es/PanelBody";
import {Ad} from "../Ads/Ad";

export default class AdBids extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      bid: null,
      ad: null,
      space: null,
      minBid: 0,
    };
  }

  async componentDidMount() {
    try {
      const bid = await this.getBid();
      const { spaceId, adId } = bid;

      const ad = await this.getAd(adId);

      const space = await this.getSpace(spaceId);

      const minBid = await this.minBid();

      this.setState({
        bid,
        ad,
        space,
        minBid,
      });
    } catch (e) {
      alert(e);
    }
  }

  getAd(adId) {
    return API.get("invad", `/ads/${adId}`);
  }

  getBid() {
    return API.get("invad", `/bids/${this.props.match.params.bidId}`);
  }

  getSpace(spaceId) {
    return API.get("invad", `/spaces/${spaceId}`);
  }

  minBid() {
    return API.get("invad", `/minBid/${this.props.match.params.spaceId}`);
  }

  async updateTopBid() {
    const minBid = await this.minBid();

    this.setState({
      minBid,
    });
  }

  createInvoice(invoice) {
    return API.post("invad", "/invoices", {
      body: invoice
    });
  }

  saveBid(bid) {
    return API.put("invad", `/bids/${this.props.match.params.id}`, {
      body: bid
    });
  }

  deleteBid() {
    return API.del("invad", `/bids/${this.props.match.params.id}`);
  }

  validateForm() {
    if( this.state.amount < this.state.minBid.amount )
    {
      alert(`Bid is below top bid of ${this.state.minBid.amount}. Only ad with top bid will display.`);
      return false;
    }
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    await this.updateTopBid();

    if(!this.validateForm()){
      return;
    }

    this.setState({ isLoading: true });

    try {

      const invoice = await this.createInvoice({
        bidId: this.state.bid.bidId,
        amount: this.state.bid.amount,
      });

      window.btcpay.showInvoice(invoice.invoiceId);

      // window.btcpay.onModalWillEnter(yourCallbackFunction);
      window.btcpay.onModalWillLeave(() => {
        this.setState({isLoading: false});
        this.props.history.push(`/adbids/${this.state.bid.bidId}`);
      });

    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  };

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this ad bid?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteBid();
      this.props.history.push("/bids");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  render() {
    return (
      <div className="Ads">
        {this.state.bid && this.state.ad && this.state.space &&
          <form>
            <PageHeader>Your ad bid</PageHeader>

            <FormGroup>
              <Panel>
                <PanelHeading>
                  <PanelTitle>
                    Ad Space
                  </PanelTitle>
                </PanelHeading>
                <PanelBody>
                  <ControlLabel>Name</ControlLabel>
                  <pre>{this.state.space.name}</pre>
                  <ControlLabel>Description</ControlLabel>
                  <pre>{this.state.space.description}</pre>
                  <ControlLabel>Space Url</ControlLabel>
                  <pre>{this.state.space.liveUrl}</pre>
                  <ControlLabel>Minimum Bid</ControlLabel>
                  <pre>${this.state.minBid.amount}</pre>
                </PanelBody>
              </Panel>

              <Panel>
                <PanelHeading>
                  <PanelTitle>
                    Your Ad
                  </PanelTitle>
                </PanelHeading>
                <PanelBody>
                  <ControlLabel>Preview</ControlLabel>
                  <Ad url={this.state.ad.url} text={this.state.ad.text} amount={this.state.bid.amount}/>
                  <br />
                  <ControlLabel>Text</ControlLabel>
                  <pre>{this.state.ad.text}</pre>
                  <ControlLabel>Url</ControlLabel>
                  <pre>{this.state.ad.url}</pre>
                  <ControlLabel>Bid Amount</ControlLabel>
                  <pre>{this.state.bid.amount}</pre>

                  <ControlLabel>Payment Status</ControlLabel>
                  <pre><strong>{ formatStatus(this.state.bid.status) }</strong></pre>
                  {
                    this.state.bid.status === 'new' &&
                    <input
                      type="image"
                      onClick={this.handleSubmit}
                      src="https://pay.invad.com/img/paybutton/pay.png"
                      name="submit"
                      style={{width:'200px'}}
                      alt="Pay with BtcPay, Self-Hosted Bitcoin Payment Processor"
                    />
                  }

                </PanelBody>
              </Panel>


            </FormGroup>


          </form>}
      </div>
    );
  }
}
