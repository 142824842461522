import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import {PageHeader, ListGroup, ListGroupItem, Button} from "react-bootstrap";

import { round } from "../../libs/format";

import "../Home.css";

export default class SpacesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      spaces: [],
      minBids: {},
      user: null,
    };
  }

  async componentDidMount() {
    try {
      const spaces = await this.spaces();
      const minBids = await this.minBids(spaces);
      const user = await Auth.currentUserInfo();

      this.setState({
        spaces,
        minBids,
        userId: user && user.id,
      });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  spaces() {
    return API.get("invad", "/allspaces");
  }

  minBid(spaceId) {
    return API.get("invad", `/minBid/${spaceId}`);
  }

  async minBids(spaces) {
    const minBidsArray = await Promise.all(spaces.map(async space => ({ minBid: (await this.minBid(space.spaceId)).amount, spaceId: space.spaceId})));
    const minBids = minBidsArray.reduce((pV, cV) => ({...pV, [cV.spaceId]: cV.minBid}), {});
    return minBids;
  }


  renderSpacesList() {
    const { spaces, minBids, userId } = this.state;

    return [{}].concat(spaces)
      .sort((spaceA, spaceB) => minBids[spaceA.spaceId] > minBids[spaceB.spaceId] ?  -1 : 1)
      // .sort((a, b) => a.createdAt > b.createdAt ?  -1 : 1)
      .map((space, i) =>
        i !== 0
          ? <LinkContainer
              key={space.spaceId}
              to={space.userId === userId ? `/spaces/${space.spaceId}` : `/adbids/new/${space.spaceId}` }
            >
              <ListGroupItem header={space.name}>
                <strong>Minimum Bid ${round(minBids[space.spaceId], 3)}</strong> -  Created: {new Date(space.createdAt).toLocaleString()}
              </ListGroupItem>
            </LinkContainer>
          : <LinkContainer
              key="new"
              to="/spaces/new"
            >
              <ListGroupItem>
                <h4>
                  <b>{"\uFF0B"}</b> List your ad space
                </h4>
              </ListGroupItem>
            </LinkContainer>
    );
  }

  render() {
    return (
      <div className="Home">
        <div className="list">
          <PageHeader>Available Ad Space</PageHeader>
          <ListGroup>
            {!this.state.isLoading && this.renderSpacesList()}
          </ListGroup>
        </div>
      </div>
    );
  }
}
