import React, {Component} from "react";
import isURL from 'validator/lib/isURL';
import isLength from 'validator/lib/isLength';

import { API } from "aws-amplify/lib/index";

import "../NewAd.css";
import {EditableAd} from "../Ads/Editable/EditableAd";
import {EditableUrl} from "../Ads/Editable/EditableUrl";
import {EditableBid} from "../Ads/Editable/EditableBid";
import {EditablePay} from "../Ads/Editable/EditablePay";

const MIN_BID = 0.00001;

export default class NewAdBid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      space: null,
      url: 'https://ad-url.com/replace-me',
      text: 'Ad text',
      amount: MIN_BID,
      minBid: {amount: 0.00001},
      reviewingAd: false,
      step: 'adtext',
      bidId: null,
    };
  }

  async componentDidMount() {
    try {
      const space = await this.getSpace();
      const minBid = await this.minBid();

      this.setState({
        space,
        minBid,
        amount: minBid.amount,
      });

    } catch (e) {
      alert(e);
    }
  }


  getSpace() {
    return API.get("invad", `/spaces/${this.props.match.params.spaceId}`);
  }

  minBid() {
    return API.get("invad", `/minBid/${this.props.match.params.spaceId}`);
  }

  createAd(ad) {
    return API.post("invad", "/ads", {
      body: ad
    });
  }

  createInvoice(invoice) {
    return API.post("invad", "/invoices", {
      body: invoice
    });
  }


  createBid(bid) {
    return API.post("invad", "/bids", {
      body: bid
    });
  }

  async updateTopBid() {
    const minBid = await this.minBid();

    this.setState({
      minBid,
    });
  }

  async validateForm() {
    if(!isURL(this.state.url)){
      alert("Invalid ad url.  Please double check.");
      return false;
    }
    if(!isLength(this.state.text, { min:3 })) {
      alert("Ad text must be at least 3");
      return false;
    }

    await this.updateTopBid();

    if( this.state.amount < this.state.minBid.amount )
    {
      alert(`Bid is below top bid of ${this.state.minBid.amount}. Only ad with top bid will display.`);
      return false;
    }
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  // handleSubmit = async event => {
  //   event.preventDefault();
  //
  //   if(!await this.validateForm()){
  //     return;
  //   }
  //
  //   this.setState({ isLoading: true });
  //
  //   try {
  //     let bidId = this.state.bidId;
  //     if(!bidId) {
  //       const ad = await this.createAd({
  //         attachment: null,
  //         text: this.state.text,
  //         url: this.state.url,
  //       });
  //
  //       const bid = await this.createBid({
  //         spaceId: this.state.space.spaceId,
  //         adId: ad.adId,
  //         amount: this.state.amount
  //       });
  //
  //       bidId = bid.bidId;
  //       this.setState({bidId});
  //     }
  //
  //     window.open(`https://invad.com/adbids/${bidId}`);
  //     this.setState({ isLoading: false});
  //
  //   } catch (e) {
  //     alert(e);
  //     this.setState({ isLoading: false });
  //   }
  // };

  async buildBid() {

    try {
      let bidId = this.state.bidId;
      if(!bidId) {
        const ad = await this.createAd({
          attachment: null,
          text: this.state.text,
          url: this.state.url,
        });

        const bid = await this.createBid({
          spaceId: this.state.space.spaceId,
          adId: ad.adId,
          amount: this.state.amount
        });

        bidId = bid.bidId;
        this.setState({bidId});
      }

      // window.open(`https://invad.com/adbids/${bidId}`);

    } catch (e) {
      alert(e);
    }
  }

  render() {
    return (
      <div className="NewAd">
        {!this.state.isLoading && this.state.space &&
        <form onSubmit={this.handleSubmit}>
          {this.state.step === 'adtext' &&
            <EditableAd
              url={this.state.url}
              text={this.state.text}
              amount={this.state.amount}
              onChange={(text) => this.setState({text})}
              next={() => this.setState({step: 'url'})}
            />
          }
          {this.state.step === 'url' &&
            <EditableUrl
              url={this.state.url}
              text={this.state.text}
              amount={this.state.amount}
              onChange={(url) => this.setState({url})}
              prev={() => this.setState({step: 'adtext'})}
              next={() => this.setState({step: 'adbid'})}
            />
          }
          {this.state.step === 'adbid' &&
            <EditableBid
              url={this.state.url}
              text={this.state.text}
              amount={this.state.amount}
              minBid={this.state.minBid.amount}
              onChange={(amount) => this.setState({amount})}
              prev={() => this.setState({step: 'url'})}
              next={() => this.setState({step: 'adpay'}, this.buildBid)}
            />
          }
          {this.state.step === 'adpay' &&
            <EditablePay
              url={this.state.url}
              text={this.state.text}
              amount={this.state.amount}
              // onPay={this.handleSubmit}
              bidId={this.state.bidId}
              prev={() => this.setState({step: 'adbid'}, () => this.setState({bidId: null}))}
            />
          }
        </form>
        }
      </div>
    );
  }

}
