import React, {Component, Fragment} from "react";
import { API } from "aws-amplify";
import {FormGroup, FormControl, ControlLabel } from "react-bootstrap";


import LoaderButton from "../../components/LoaderButton";
import "../NewAd.css";

export default class NewBid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      space: null,
      ads: [],
      adId: null,
      amount: 0
    };
  }

  async componentDidMount() {
    try {
      const space = await this.getSpace();
      const ads = await this.ads();

      this.setState({
        space,
        ads,
      });
    } catch (e) {
      alert(e);
    }
  }

  ads() {
    return API.get("invad", "/ads");
  }

  getSpace() {
    return API.get("invad", `/spaces/${this.props.match.params.spaceId}`);
  }

  createBid(bid) {
    return API.post("invad", "/bids", {
      body: bid
    });
  }

  validateForm() {
    //TODO: Validation
    if( !this.state.amount > 0 )
    {
      return false;
    }
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.createBid({
        spaceId: this.state.space.spaceId,
        adId: this.state.adId,
        amount: this.state.amount
      });
      this.props.history.push("/bids");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  /*
   <form method="POST" action="https://pay.invad.com/api/v1/invoices" target="_blank">
          <input type="hidden" name="storeId" value="6M2uJbthezgNYNyFAFe1xYp1hXexydswYaULjM613TDU" />
          <input type="hidden" name="price" value="10" />
          <input type="hidden" name="currency" value="USD" />
          <input type="image" src="https://pay.invad.com/img/paybutton/pay.png" name="submit" style="width:146px" alt="Pay with BtcPay, Self-Hosted Bitcoin Payment Processor"/>
      </form>
   */

  render() {
    return (
      <div className="NewAd">
        {!this.state.isLoading && this.state.space &&
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="name">
            <ControlLabel>Ad Space</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.space.name}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup controlId="adId">
            {
              this.state.ads.map(ad =>
                <Fragment>
                  <FormControl type="radio" onChange={this.handleChange} value={ad.adId}/>
                  <ControlLabel>{ad.text}</ControlLabel>
                </Fragment>)
            }
          </FormGroup>
          <FormGroup controlId="amount">
            <ControlLabel>Amount</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.amount}
              componentClass="input"
            />
          </FormGroup>

          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
        }
      </div>
    );
  }
}
