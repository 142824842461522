import React, { Component } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { s3Upload } from "../../libs/awsLib";
import config from "../../config";
import "../NewAd.css";

export default class NewAd extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      text: "",
      url: ""
    };
  }

  createAd(ad) {
    return API.post("invad", "/ads", {
      body: ad
    });
  }

  validateForm() {
    return this.state.text.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  handleSubmit = async event => {
    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    this.setState({ isLoading: true });

    try {
      const attachment = this.file
        ? await s3Upload(this.file)
        : null;

      await this.createAd({
        attachment,
        text: this.state.text,
        url: this.state.url,
      });
      this.props.history.push("/ads");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <div className="NewAd">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="text">
            <ControlLabel>Ad Text</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.text}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup controlId="url">
            <ControlLabel>Ad Url</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.url}
              componentClass="input"
            />
          </FormGroup>
          {/*<FormGroup controlId="file">*/}
            {/*<ControlLabel>Attachment</ControlLabel>*/}
            {/*<FormControl onChange={this.handleFileChange} type="file" />*/}
          {/*</FormGroup>*/}
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}
