import React, {Component, Fragment} from "react";
import {API, Auth} from "aws-amplify";
import {FormGroup, FormControl, ControlLabel, Button} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import "../Ads.css";
import {getSpaceCode} from "../../libs/iframeLib";

export default class Ads extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: true,
      isEditing: false,
      isDeleting: null,
      space: null,
      name: "",
      description: "",
      liveUrl: "",
      minBid: null,
      isOwner: false,
    };
  }

  async componentDidMount() {
    try {
      const space = await this.getSpace();
      const { name, description, liveUrl } = space;
      const minBid = await this.minBid(space.spaceId);
      const user = await Auth.currentUserInfo();

      this.setState({
        space,
        name,
        description,
        liveUrl,
        minBid,
        isOwner: user && space.userId === user.id,
        isLoading: false,
      });
    } catch (e) {
      alert(e);
    }
  }

  minBid(spaceId) {
    return API.get("invad", `/minBid/${spaceId}`);
  }

  getSpace() {
    return API.get("invad", `/spaces/${this.props.match.params.id}`);
  }

  saveSpace(space) {
    return API.put("invad", `/spaces/${this.props.match.params.id}`, {
      body: space
    });
  }

  deleteSpace() {
    return API.del("invad", `/spaces/${this.props.match.params.id}`);
  }

  validateForm() {
    return this.state.name.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.saveSpace({
        name: this.state.name,
        description: this.state.description,
        liveUrl: this.state.liveUrl,
      });
      this.props.history.push("/spaces");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this ad space?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteSpace();
      this.props.history.push("/spaces");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  };

  render() {
    return !this.state.isLoading && (
      <div className="Ads">
        { this.state.isOwner &&
          <div>
            <FormGroup>

              <ControlLabel>Install</ControlLabel>
              <p>Paste the code below on your website, where the ad should be visible</p>
              <pre>
                  { !this.state.isLoading && getSpaceCode(this.state.space.spaceId) }
                </pre>
            </FormGroup>

            <FormGroup>
              <ControlLabel>Minimum Bid</ControlLabel>
              <p>This is set automatically though bidding.</p>
              <pre>
                  ${ this.state.minBid.amount }
                </pre>
            </FormGroup>
            {this.state.isEditing &&
            <form onSubmit={this.handleSubmit}>
              <FormGroup controlId="name">
                <ControlLabel>Ad Space Name</ControlLabel>

                <FormControl
                  onChange={this.handleChange}
                  value={this.state.name}
                  componentClass="input"
                />
              </FormGroup>
              <FormGroup controlId="description">
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.description}
                  componentClass="textarea"
                />
              </FormGroup>
              <FormGroup controlId="liveUrl">
                <ControlLabel>Sample Url</ControlLabel>
                <FormControl
                  onChange={this.handleChange}
                  value={this.state.liveUrl}
                  componentClass="input"
                />
              </FormGroup>

              <LoaderButton
                block
                bsStyle="primary"
                bsSize="large"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Save"
                loadingText="Saving…"
              />
              <LoaderButton
                block
                bsStyle="danger"
                bsSize="large"
                isLoading={this.state.isDeleting}
                onClick={this.handleDelete}
                text="Delete"
                loadingText="Deleting…"
              />
            </form>
            }

          </div>  }
        { (!this.state.isOwner || !this.state.isEditing) &&
          <Fragment>
            <ControlLabel>Ad Space Name</ControlLabel>
            <p>{this.state.name}</p>
            <ControlLabel>Description</ControlLabel>
            <p>{this.state.description}</p>
            <ControlLabel>Sample Url</ControlLabel>
            <p><a href={this.state.liveUrl} target="_blank" without rel="noopener noreferrer">{this.state.liveUrl}</a></p>
            <br />

            {this.state.isOwner ?
              <LoaderButton
                block
                bsStyle="primary"
                bsSize="large"
                disabled={!this.validateForm()}
                onClick={() => this.setState({isEditing: true})}
                isLoading={this.state.isLoading}
                text="Edit"
                loadingText="Saving…"
              />
              :
              <a href={`/adbids/new/${this.state.space.spaceId}`}>Bid now</a>
            }
          </Fragment>
        }
      </div>
    );
  }
}
