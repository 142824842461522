import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import AdsList from "./containers/Ads/AdsList";
import SpacesList from "./containers/Spaces/SpacesList";
import BidsList from "./containers/Bids/BidsList";
import Ads from "./containers/Ads/Ads";
import Spaces from "./containers/Spaces/Spaces";
import Bids from "./containers/Bids/Bids";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Account from "./containers/Account";
import NewAd from "./containers/Ads/NewAd";
import NewAdBid from "./containers/AdBids/NewAdBid";
import NewAdBidInline from "./containers/AdBids/NewAdBidInline";
import NewSpace from "./containers/Spaces/NewSpace";
import NewBid from "./containers/Bids/NewBid";
import AdBids from "./containers/AdBids/AdBids";
import AdBidsList from "./containers/AdBids/AdBidsList";
// import Settings from "./containers/Settings";
import AllSpacesList from "./containers/Spaces/AllSpacesList";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    {/*<AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />*/}

    <AppliedRoute path="/allspaces" exact component={AllSpacesList} props={childProps} />

    <AppliedRoute path="/ads" exact component={AdsList} props={childProps} />
    <AuthenticatedRoute path="/ads/new" exact component={NewAd} props={childProps} />
    <AuthenticatedRoute path="/ads/:id" exact component={Ads} props={childProps} />

    <AppliedRoute path="/adbids" exact component={AdBidsList} props={childProps} />
    <AppliedRoute path="/adbids/new/:spaceId" exact component={NewAdBid} props={childProps} />
    <AppliedRoute path="/adbids/new/:spaceId/inline" exact component={NewAdBidInline} props={childProps} />
    <AppliedRoute path="/adbids/:bidId" exact component={AdBids} props={childProps} />

    <AppliedRoute path="/spaces" exact component={SpacesList} props={childProps} />
    <AuthenticatedRoute path="/spaces/new" exact component={NewSpace} props={childProps} />
    <AppliedRoute path="/spaces/:id" exact component={Spaces} props={childProps} />

    <AppliedRoute path="/bids" exact component={BidsList} props={childProps} />
    <AuthenticatedRoute path="/bids/new" exact component={NewBid} props={childProps} />
    <AuthenticatedRoute path="/bids/new/spaceId/:spaceId" exact component={NewBid} props={childProps} />
    <AuthenticatedRoute path="/bids/:id" exact component={Bids} props={childProps} />

    <AuthenticatedRoute path="/account" exact component={Account} props={childProps} />

      { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
